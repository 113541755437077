import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import styles from "./NavBar.module.scss";
import "tippy.js/dist/tippy.css"; // optional
import Logo from "./../../assets/images/logo.png";
import {
  setIsShowMobileMenuAction,
  setOnlineStatusAction,
} from "../../redux/utils/actions";
import { setAuthStateEmptyAction } from "../../redux/auth/action";
import RoutingList, { navMenus } from "../../constants/RoutingList";

const Navbar = () => {
  const { user } = useSelector((state) => state.auth);
  const { onlineStatus, isShowMobileMenu } = useSelector(
    (state) => state.utils
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuRef = useRef();

  const [isShowMenu, setIsShowMenu] = useState(false);

  const changeVisibility = () => {
    setIsShowMenu((prev) => !prev);
  };

  const logoutHandler = () => {
    changeMobileMenuVisibility();
    dispatch(setAuthStateEmptyAction());
    window.location.reload(true);
  };

  const navigateToProfilePage = () => {
    changeMobileMenuVisibility();
    navigate(RoutingList.profile.index);
  };

  const navigateToHavingTroublePage = () => {
    changeMobileMenuVisibility();
    navigate(RoutingList.troubleshoot.index);
  };

  const changeMobileMenuVisibility = () =>
    dispatch(setIsShowMobileMenuAction(!isShowMobileMenu));

  useEffect(() => {
    const setIsOnlineTrue = () => {
      dispatch(setOnlineStatusAction(true));
      toast.success("Online");
    };
    const setIsOnlineFalse = () => {
      dispatch(setOnlineStatusAction(false));
      toast.error("Offline");
    };

    window.addEventListener("online", setIsOnlineTrue);
    window.addEventListener("offline", setIsOnlineFalse);

    return () => {
      window.removeEventListener("online", setIsOnlineTrue);
      window.removeEventListener("offline", setIsOnlineFalse);
    };
  }, [dispatch]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, isShowMenu]);

  // const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

  return (
    <header>
      <div className="container">
        <div className="header-wrap">
          <div className="logo-wrap">
            <Link className="" to={RoutingList.index}>
              <Tippy content={"Home"}>
                <img src={Logo} alt="logo" />
              </Tippy>
            </Link>
          </div>
          <ul className={`header-menu ${isShowMobileMenu ? "active" : ""}`}>
            {navMenus.map((menu) => (
              <li key={menu.url}>
                <Tippy content={menu.label}>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={menu.url}
                    onClick={changeMobileMenuVisibility}
                  >
                    <i className={menu.icon}></i>
                    <span>{menu.label}</span>
                  </NavLink>
                </Tippy>
              </li>
            ))}

            <li ref={menuRef}>
              <Tippy content={"Profile"}>
                <Link to="#" onClick={changeVisibility}>
                  <i className={`icon icon-user ${styles.hovered}`}></i>
                  <span>
                    {user.sadhak_profile.syid}{" "}
                    {onlineStatus ? "" : " | (OFFLINE) "}
                  </span>
                </Link>
              </Tippy>
              <div
                className={`user-profile-wrap ${isShowMenu ? "active" : ""}`}
              >
                <ul className={styles.topBottomPadding}>
                  <li className={styles.li}>
                    <button
                      type="button"
                      className={styles.btn_menu}
                      onClick={navigateToProfilePage}
                    >
                      Profile
                    </button>
                  </li>
                  <li className={styles.li}>
                    <button
                      type="button"
                      className={styles.btn_menu}
                      onClick={navigateToHavingTroublePage}
                    >
                      Troubleshoot?
                    </button>
                  </li>
                  <li className={styles.li}>
                    <button
                      type="button"
                      className={styles.btn_menu}
                      onClick={logoutHandler}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div
            className={`hamburger-menu jsMenuToggle ${
              isShowMobileMenu ? "active" : ""
            } `}
            onClick={changeMobileMenuVisibility}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
