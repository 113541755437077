const isValidUserForAccess = (_user) => {
  let isValid = false;
  if (
    _user &&
    (_user.super_admin ||
      _user.club_admin ||
      _user.digital_store_admin ||
      _user.is_active_board_member ||
      _user.is_farmer_episodes_accessible ||
      _user.is_shivir_episodes_accessible)
  ) {
    isValid = true;
  } else if (_user.is_active_forum_member) {
    isValid = _user?.collections?.some(
      (collection) => collection?.digital_asset_ids?.length > 0
    );
  }
  return isValid;
};

export const authUserForLogin = (_user) => {
  let result = { res: 0, message: "" };
  if (isValidUserForAccess(_user)) {
    result.message = "You have successfully logged in.";
    result.res = 1;
  } else {
    result.message = "You are not a active board member of Shiv Yog Forums.";
  }
  return result;
};
