import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import authService from "../../services/auth";
import * as tokenService from "../../services/token";

import { setUserProfileAction } from "../../redux/auth/action";
import { logger } from "../../utils/logger";

import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import VersionCheck from "../VersionCheck/VersionCheck";
import RoutingList from "../../constants/RoutingList";

const PrivateRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { onlineStatus } = useSelector((state) => state.utils);
  const { user } = useSelector((state) => state.auth);

  // Using a ref to prevent unnecessary API calls
  const hasFetchedData = useRef(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (onlineStatus && !hasFetchedData.current) {
          hasFetchedData.current = true; // Mark as fetched to prevent subsequent calls
          const res = await authService.get_user();
          tokenService.setAuthToken(res?.data?.user?.authentication_token);
          dispatch(setUserProfileAction(res?.data?.user));
        }
      } catch (e) {
        logger.error(e);
        hasFetchedData.current = false; // Reset on error
      }
    };

    const pathnames = [
      RoutingList.index,
      RoutingList.downloads.index,
      RoutingList.troubleshoot.index,
      RoutingList.profile.index,
    ];

    if (pathnames.includes(location.pathname) && user.isAuth && !hasFetchedData.current) {
      getData();
    }

    // Reset the ref when location changes to a non-matching path
    if (!pathnames.includes(location.pathname)) {
      hasFetchedData.current = false;
    }
  }, [dispatch, location.pathname, onlineStatus, user.isAuth]);

  return user.isAuth ? (
    <>
      <VersionCheck />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate to={RoutingList.login.index} state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
