import React from "react";
import SweetAlert2 from "react-sweetalert2";

const SwalAlert = ({
  onConfirm,
  setAlertVisibility,
  show = false,
  title = "Delete offline video?",
  text = "You won't be able to revert this!",
  icon = "warning",
  showCancelButton = true,
  confirmButtonColor = "#3085d6",
  cancelButtonColor = "#d33",
  confirmButtonText = "Yes, delete it!",
  ...rest
}) => {
  const hideAlert = () => {
    setAlertVisibility(false);
  };

  return (
    <SweetAlert2
      {...{
        show,
        title,
        text,
        icon,
        showCancelButton,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonColor,
      }}
      didOpen={() => {
        // run when swal is opened...
      }}
      didClose={() => {
        hideAlert();

        // run when swal is closed...
      }}
      onConfirm={async (result) => {
        await onConfirm();
        hideAlert();

        // run when clieked in confirm and promise is resolved...
      }}
      onError={(error) => {
        // run when promise rejected...
      }}
      onResolve={(result) => {
        // run when promise is resolved...
      }}
      {...rest}
    />
  );
};

export default SwalAlert;
