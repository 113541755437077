import { set_UPDATE_DB_INDEX_action } from "../videoStorage/actions";
import { utilitySlice } from "./slice";
const { actions: slice } = utilitySlice;

export const setLoadingAction = (payload) => (dispatch) => {
  dispatch(slice.setLoading(payload));
};

export const setDevtoolOpenAction = (payload) => (dispatch) => {
  dispatch(slice.setDevtoolsOpen(payload));
};

export const setOnlineStatusAction = (payload) => (dispatch) => {
  dispatch(slice.setOnlineStatus(payload));
};

export const setDownloadInProgressAction = (payload) => (dispatch) => {
  dispatch(slice.setDownloadInProgress(payload));
};

export const setDownloadQueueAction = (payload) => async (dispatch) => {
  const [dbVideos] = await Promise.all([window.storage.list()]);
  await dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: dbVideos }));
  await dispatch(slice.setDownloadQueue(payload));
};

export const setToggleMenuAction = (payload) => (dispatch) => {
  dispatch(slice.setToggleMenu(payload));
};

export const setIsShowMobileMenuAction = (payload) => (dispatch) => {
  dispatch(slice.setIsShowMobileMenu(payload));
};

export const setRetryModalAction = (payload) => (dispatch) => {
  dispatch(slice.SET_RETRY_MODAL(payload));
}