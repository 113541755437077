import React from "react";
import { useSelector } from "react-redux";

import VideoCollectionsCard from "../../components/Cards/VideoCollectionsCard/VideoCollectionsCard";

const Downloads = () => {
  const { dbIndex } = useSelector((state) => state.video);
  const { user } = useSelector((state) => state.auth);

  const doThisUserHasOfflineVideo = () => {
    return dbIndex.some((offV) => {
      return offV.appMetadata.syid === user.sadhak_profile.syid;
    });
  };

  function findCommonVideos() {
    const dbIndexIds = dbIndex?.flatMap((item) => item.appMetadata?.id);
    const commonVideos = user?.collections?.flatMap((collection) =>
      collection?.digital_assets?.filter((asset) =>
        dbIndexIds?.includes(asset?.id)
      )
    );
    return commonVideos;
  }

  return (
    <>
      {user.collections.map((collection) => (
        <VideoCollectionsCard
          key={collection.id}
          collection={collection}
          offlineView={true}
        />
      ))}

      {findCommonVideos()?.length === 0 && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <h1>No Offline video found</h1>
        </div>
      )}
    </>
  );
};

export default Downloads;
