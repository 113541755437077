import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "../../modules/authentication/Login/Login";
import Downloads from "../../modules/Downloads/Downloads";
import Home from "../../modules/Home/Home";
import Profile from "../../modules/Profile/Profile";
import StorageInfo from "../../modules/StorageInfo/StorageInfo";
import Video from "../../modules/Video/Video";
import ErrorPage from "../ErrorPage/ErrorPage";
import PrivateRoute from "./PrivateRoute";

import RoutingList from "../../constants/RoutingList";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path={RoutingList.index} element={<Home />} />
            <Route path={RoutingList.downloads.index} element={<Downloads />} />
            <Route path={RoutingList.profile.index} element={<Profile />} />
            <Route
              path={RoutingList.troubleshoot.index}
              element={<StorageInfo />}
            />
            <Route
              path={`${RoutingList.video.index}/:id`}
              element={<Video />}
            />
          </Route>

          <Route path={RoutingList.login.index} element={<Login />} />
          <Route
            path={RoutingList.page_not_found}
            element={<ErrorPage message={"No Pages found!"} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
