import { createSlice } from "@reduxjs/toolkit";
import { filterDigitalAssets } from "./action";
const lastHardRefreshTime = new Date().toLocaleDateString();

const initState = {
  user: {
    isAuth: false,
    updateNeeded: false,
    id: "",
    username: "",
    authentication_token: "",
    sy_club: {},
    club_admin: false,
    board_member_position: "",
    board_member_forum_name: "",
    is_board_member: false,
    is_valid_board_member: false,
    unpaid_board_members: [],
    forum_min_member_count: 0,
    has_min_forum_members: false,
    collections: [],
    is_shivir_episodes_accessible: false,
    is_farmer_episodes_accessible: false,
    digital_store_admin: false,
    super_admin: false,
    is_active_board_member: false,
    sadhak_profile: {},
    address: {},
    reboot: false,
  },
  lastHardRefreshTime: '',
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setAuthStateEmpty: (state, action) => {
      return initState;
    },
    setUserProfile: (state, action) => {
      const { payload } = action;
      const collections = filterDigitalAssets(payload.collections);
      const user = {
        ...payload,
        collections,
      };
      state.user = {
        ...state.user,
        ...user,
        // lastHardRefreshTime,
        // isAuth: true
      };
    },
    setLastHardRefreshTime: (state, action) => {
      state.lastHardRefreshTime = lastHardRefreshTime
    },
    setLastHardRefreshOld: (state, action) => {
      state.lastHardRefreshTime= "23/03/1995"
    },
  },
});
