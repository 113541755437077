import React from "react";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "tippy.js/dist/tippy.css"; // optional
import DownloadButton from "./DownloadButton";
import DownloadQueueButton from "./DownloadQueueButton";
import RemoveButton from "./RemoveButton";

import RoutingList from "../../../constants/RoutingList";
import logServices from "../../../services/logger";
import PlayIcon from "./../../../assets/images/play.png";
import Thumbnail from "./../../../assets/images/thumbnail.jpg";

import { set_UPDATE_DB_INDEX_action } from "../../../redux/videoStorage/actions";
import {
  getAllOfflineVideoList,
  removeOffline,
  saveOffline,
} from "../../../utils/offlineVideoStorage";

const VideoCards = ({ digital_assets }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dbIndex } = useSelector((state) => state.video);
  const { user } = useSelector((state) => state.auth);
  const { downloadInProgress, onlineStatus, downloadQueue } = useSelector(
    (state) => state.utils
  );

  const { id, asset_name, asset_description, asset_url } = digital_assets;

  const navigateState = { ...digital_assets };
  const videoPageUrl = `${RoutingList.video.index}/${id}`;

  const checkOfflineAvailability = () =>
    dbIndex.some(
      (v) =>
        v.appMetadata.id === id &&
        v.appMetadata.syid === user.sadhak_profile.syid
    );
  const checkQueueAvailability = () =>
    downloadQueue.some((x) => x.digital_assets.id === id);

  const getDownloadPercentage = () =>
    downloadInProgress.progress !== 0 &&
    downloadInProgress?.content?.originalManifestUri === asset_url
      ? `Downloading ... ${Math.floor(downloadInProgress.progress * 100)}%`
      : "";

  const navigateToVideoPage = () => {
    if (onlineStatus === false && !checkOfflineAvailability()) {
      toast.warn("You are offline.");
      return;
    }
    navigate(videoPageUrl, { state: navigateState });
  };

  const onDownloadStart = async () => {
    await logServices.logger({
      chrome_log: {
        user_id: user?.id,
        asset_id: digital_assets.id,
        status: "DOWNLOAD_INITIATED",
        date_time: new Date().toLocaleString().replaceAll("/", "-"),
        data: { message: "Downloading Initiated", user },
      },
    });
  };

  const onDownloadComplete = async () => {
    await logServices.logger({
      chrome_log: {
        user_id: user?.id,
        asset_id: digital_assets.id,
        status: "DOWNLOAD_SUCCESS",
        date_time: new Date().toLocaleString().replaceAll("/", "-"),
        data: { message: "Downloading success", user },
      },
    });
  };

  const downloadVideo = async () => {
    if (checkOfflineAvailability()) {
      toast.warn("You already have the video in your library.");
      return;
    }
    
    await onDownloadStart()
    await saveOffline(
      digital_assets,
      {
        downloaded: Date(),
        ...digital_assets,
        syid: user.sadhak_profile.syid,
      }
    );
    await onDownloadComplete()
  };

  const removeMedia = async () => {
    //TODO: deleting while a download is in progress messes up the progress bar. thanks shaka
    if (!checkOfflineAvailability()) {
      return;
    }
    const videoList = await getAllOfflineVideoList();
    const { offlineUri } = videoList.find(
      (video) => video?.originalManifestUri === asset_url
    );
    await removeOffline(offlineUri);
    await logServices.logger({
      chrome_log: {
        user_id: user?.id,
        asset_id: id,
        status: "DELETED_FROM_USER_DOWN_LIST",
        date_time: new Date().toLocaleString().replaceAll("/", "-"),
        data: { message: "Deleted from user downloaded video", user },
      },
    });
    const newDbIndex = await getAllOfflineVideoList();
    dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: newDbIndex }));
    toast.error("Offline video removed");
  };

  return (
    <div className="video-cards-row w-33">
      <div className="video-cards">
        <div className="thumbnail-container" onClick={navigateToVideoPage}>
          <Link to={"#"} className="img-wrapper">
            <img
              src={Thumbnail}
              alt="thumbnail"
              className={onlineStatus ? "" : "grayscale"}
            />
          </Link>
          <Tippy content={"Play"} placement="top">
            <img
              src={PlayIcon}
              className="play-button"
              alt=""
              style={{
                visibility:
                  onlineStatus === false && !checkOfflineAvailability()
                    ? "hidden"
                    : "visible",
              }}
            />
          </Tippy>
          {checkOfflineAvailability() ? (
            <span className="downloaded-txt">Downloaded</span>
          ) : (
            <></>
          )}
        </div>
        <div className="video-cards-desc">
          <Tippy content={asset_name} placement="top">
            <h2 onClick={navigateToVideoPage}>{asset_name}</h2>
          </Tippy>
          {checkOfflineAvailability() ? (
            <RemoveButton removeMedia={removeMedia} />
          ) : checkQueueAvailability() ? (
            <DownloadQueueButton />
          ) : (
            <DownloadButton
              getDownloadPercentage={getDownloadPercentage}
              downloadVideo={downloadVideo}
            />
          )}

          <p>{asset_description}</p>
        </div>
      </div>
    </div>
  );
};

export default VideoCards;
