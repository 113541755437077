const ErrorPage = ({ message = "" }) => {
  return (
    <div>
      <body className="theme-bg">
        <main>
          <section className="error-page-box">
            <div className="container">
              <div className="error-page-wrap">
                <h3>(._.)</h3>
                <h5>{message}</h5>
                <p>
                  If you keep receiving this error. Please contact an admin.
                </p>
              </div>
            </div>
          </section>
        </main>
      </body>
    </div>
  );
};

export default ErrorPage;
