import ErrorPage from "../ErrorPage/ErrorPage";

const BrowserCompatibility = ({ children }) => {
  const [browserName, browserVersion] = (() => {
    let ua = navigator.userAgent;
    let tem;
    let browserInfo =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(browserInfo[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (browserInfo[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    browserInfo = browserInfo[2]
      ? [browserInfo[1], browserInfo[2]]
      : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      browserInfo.splice(1, 1, tem[1]);
    return browserInfo;
  })();

  const isSupportedBrowser = () =>
    browserName === "Chrome" && +browserVersion >= 100;

  if (browserName !== "Chrome") {
    return (
      <ErrorPage message="Sorry, we are currently supporting chrome as of now." />
    );
  }

  if (!isSupportedBrowser()) {
    return (
      <ErrorPage message="Sorry, we are currently supporting chrome with version 100 and above" />
    );
  }

  return <div>{children}</div>;
};

export default BrowserCompatibility;
