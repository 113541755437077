const RoutingList = {
  index: "/",
  downloads: {
    index: "/downloads",
  },
  video: {
    index: "/video",
  },
  profile: {
    index: "/profile",
  },
  login: {
    index: "/login",
  },
  troubleshoot: {
    index: "/troubleshoot",
  },
  page_not_found: "/*",
};


export const navMenus = [
  {
    label: "Home",
    url: RoutingList.index,
    icon: "icon icon-home",
  },
  {
    label: "My Downloads",
    url: RoutingList.downloads.index,
    icon: "icon icon-downloads",
  },
];

export default RoutingList;
