import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

export const loginInitialValue = {
  username: "",
  password: "",
  rememberPassword: false,
};

export const loginAuthCheckInitialValue = {
  isAuth: false,
};

export const loginAuthCheckValidationSchema = Yup.object().shape({
  isAuth: Yup.boolean().oneOf([true], "Please accept the OATH Letter"),
});
