import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import {
  setAuthStateEmptyAction,
  setLastHardRefreshTimeAction,
} from "../../redux/auth/action";
import { setIsShowMobileMenuAction } from "../../redux/utils/actions";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SwalAlert from "../SwalAlert/SwalAlert";

const VersionCheck = () => {
  const { app_version } = useSelector((state) => state.auth.user);
  const { lastHardRefreshTime } = useSelector((state) => state.auth);

  const [isShowAlert, setIsShowAlert] = useState(false);
  const dispatch = useDispatch();
  const { isShowMobileMenu } = useSelector((state) => state.utils);

  const changeMobileMenuVisibility = () =>
    dispatch(setIsShowMobileMenuAction(!isShowMobileMenu));

  const onConfirm = () => {
    changeMobileMenuVisibility();
    dispatch(setAuthStateEmptyAction());
    window.location.reload(true);
  };

  useEffect(() => {
    if (app_version !== config.app_version) {
      setIsShowAlert(true);
    }
  }, [app_version]);

  useEffect(() => {
    if (lastHardRefreshTime !== new Date().toLocaleDateString()) {
      dispatch(setLastHardRefreshTimeAction());
      window.location.reload(true);
    }
  }, [dispatch, lastHardRefreshTime]);

  return (
    <div>
      <SwalAlert
        show={isShowAlert}
        title="Reload the application"
        text={`Your app version is ${config.app_version}, current version of app is ${app_version}. You have to clear browser caches and refresh your browser.`}
        confirmButtonText="Click here to refresh"
        setAlertVisibility={setIsShowAlert}
        onConfirm={onConfirm}
        showCancelButton={false}
        allowOutsideClick={false}
        allowEscapeKey={false}
      >
        {app_version !== config.app_version && (
          <>
            <p style={{ marginTop: "20px" }}>Refreshing in</p>
            <div className="d-flex justify-content-center align-items-center">
              <CountdownCircleTimer
                isPlaying
                duration={7}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[7, 5, 2, 0]}
                size={50}
                strokeWidth={5}
                onComplete={onConfirm}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
            <p>Seconds</p>
          </>
        )}
      </SwalAlert>
    </div>
  );
};

export default VersionCheck;
