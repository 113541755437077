const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_VERSION = process.env.REACT_APP_app_version;

const config = {
  baseUrl: BASE_URL,
  apiUrl: API_URL,
  app_version: APP_VERSION,
  endPoints: {
    auth: {
      login: "/chrome/api/v2/sessions/sign_in",
      get_user: "/chrome/api/v2/sessions/get_user",
    },
    reboot: {
      changeStatus: "/chrome/api/v2/sadhak_profiles/remove_from_reboot",
    },
    logger: "/chrome/api/v2/chrome_logs"
  },
};


export default config;
