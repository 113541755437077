import React from "react";
import { useSelector } from "react-redux";

const Profile = () => {
  const { user } = useSelector((state) => state.auth);
  const { sadhak_profile, address } = user;
  return (
    <>
      <section className="profile-page-box">
        <div className="container">
          <div className="profile-page-wrap">
            <div className="profile-section-top">
              <h2>Profile</h2>
            </div>
            <div className="profile-section-bottom">
              <div className="profile-img-wrap">
                <div className="profile-img-left">
                  <span className="perfect-center">
                    {sadhak_profile.first_name?.[0] || " "}
                  </span>
                </div>
                <div className="profile-sec-right">
                  <h3>
                    {sadhak_profile.first_name + " " + sadhak_profile.last_name}
                  </h3>
                  <p>{user?.board_member_position}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info-wrap">
            <div className="profile-info-list">
              <span className="label-text">SY ID</span>
              <p>{sadhak_profile.syid}</p>
            </div>
            <div className="profile-info-list">
              <span className="label-text">Email</span>
              <p>{sadhak_profile.email}</p>
            </div>
            <div className="profile-info-list">
              <span className="label-text">First name</span>
              <p>{sadhak_profile.first_name}</p>
            </div>
            <div className="profile-info-list">
              <span className="label-text">Last name</span>
              <p>{sadhak_profile.last_name}</p>
            </div>
            <div className="profile-info-list">
              <span className="label-text">Gender</span>
              <p>{sadhak_profile.gender.toString()}</p>
            </div>
            <div className="profile-info-list">
              <span className="label-text">Date of birth</span>
              <p>{sadhak_profile.date_of_birth}</p>
            </div>
            {/* <div className="profile-info-list">
              <span className="label-text">Contact number</span>
              <p>(+91) XXXXXXX</p>
            </div> */}
            <div className="profile-info-list">
              <span className="label-text">Address</span>
              <p>
                <span>{address?.first_line},</span>{" "}
                <span>{address?.second_line},</span>{" "}
                <span>{address?.district}, </span>{" "}
                <span>{address.city_name}, </span>{" "}
                <span>{address?.state_name}, </span>{" "}
                <span>{address?.state_name}</span>{" "}
              </p>
            </div>
            {(user?.super_admin || user?.is_active_board_member) && (
              <div className="profile-info-list">
                <span className="label-text">Roles</span>
                <div className="profile-role-group">
                  {user?.super_admin && (
                    <span className="profile-role-badges-row">
                      <span className="profile-role-badge">Super Admin</span>
                    </span>
                  )}
                  {user?.is_active_board_member && (
                    <span className="profile-role-badges-row">
                      <span className="profile-role-badge">
                        Active Board Member
                      </span>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
