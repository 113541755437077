import * as storage from "../utils/storage";

import { AUTH_TOKEN } from "../constants/storage";

/**
 * Persist token to storage.
 *
 * @param {string} authToken
 */
export function persist(authToken) {
  storage.set(AUTH_TOKEN, authToken);
}

/**
 * Get access token from storage.
 *
 * @returns {string}
 */
export function getAuthToken() {
  return storage.get(AUTH_TOKEN);
}

/**
 * Set access token from storage.
 *
 * @param {string} authToken
 */
export function setAuthToken(authToken) {
  storage.set(AUTH_TOKEN, authToken);
}

/**
 * Clear tokens.
 */
export function clear() {
  storage.remove(AUTH_TOKEN);
}
