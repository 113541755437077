import { createSlice } from "@reduxjs/toolkit";

const initialConfiguration = {
  language: "en",
  subtitles: "en",
  quality: 480,
};

const initState = {
  configuration: initialConfiguration,
  dbIndex: [],
  isInit: false,
  isSupported: false,
  videos: [],
  player: null,
  storage: null,
};

export const videoStorageSlice = createSlice({
  name: "video",
  initialState: initState,
  reducers: {
    setEmpty: (state, action) => {
      // init();
      return initState;
    },
    set_INIT_DONE: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        isInit: payload.isInit,
        isSupported: payload.isSupported,
        videos: payload.videos,
        dbIndex: payload.dbIndex,
        configuration: payload.configuration,
        player: payload.player,
        storage: payload.storage,
      };
    },
    set_UPDATE_DB_INDEX: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        dbIndex: payload.dbIndex,
      };
    },
  },
});
