import React from "react";
import { useSelector } from "react-redux";

const DownloadQueueButton = () => {
  const { onlineStatus } = useSelector((state) => state.utils);

  return onlineStatus ? (
    <button
      className={"download-btn download-inprogress"} // add 'download-inprogress' class when download inprogress
      type="button"
    >
      <>In Download Queue</>
    </button>
  ) : (
    <></>
  );
};

export default DownloadQueueButton;
