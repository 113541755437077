import React from "react";
import { useSelector } from "react-redux";
import config from "../../config";
import SwalAlert from "../SwalAlert/SwalAlert";

const UpdateVersion = () => {
  // const [updateNeeded, setUpdateNeeded] = useState(false);
  const { app_version,updateNeeded  } = useSelector((state) => state.auth.user);

  const updateApp = () => {
    window.location.reload(true);
  };
  // useEffect(() => {
  //   // process.env.NODE_ENV === "production" &&
  //   serviceWorkerRegistration.update(setUpdateNeeded);
  // });

  return (
    <div>
      {updateNeeded && (
        // <UpdateAlert
        //   text="There is a new version available."
        //   buttonText="Update"
        //   onClick={updateApp}
        // />
        <SwalAlert
          show={updateNeeded}
          title="Reload the application"
          text={`Your app version is ${config.app_version}, current version of app is ${app_version}. Please refresh your application by pressing 'ctrl+shift+r' then proceed. If still causing the issue close the app/uninstall the app and open again.`}
          confirmButtonText="Yes, I have refreshed"
          // setAlertVisibility={setUpdateNeeded}
          onConfirm={updateApp}
          showCancelButton={false}
          allowOutsideClick={false}
          allowEscapeKey={false}
        ></SwalAlert>
      )}
    </div>
  );
};

export default UpdateVersion;
