import config from "../config";
import http from "../utils/http";

const logger = async (payload) => {
  const url = `${config.apiUrl}${config.endPoints.logger}`;
  const {chrome_log}= payload;
  const {data}= chrome_log;
  return http.post(url, {...chrome_log, data: JSON.stringify(data)}, {headers: {
    'Content-Type': 'application/json'
  }});
};

const logServices = {
  logger,
};

export default logServices;
