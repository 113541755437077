import authService from "./../../services/auth";

import { logger } from "../../utils/logger";
import { authSlice } from "./slice";

const { actions: slice } = authSlice;

export const userSignIn = (payload) => {
  return async (dispatch) => {
    try {
      const res = await authService.login(payload);
      const { user } = res?.data;
      return Promise.resolve(user);
    } catch (error) {
      logger.error({ error: error });
      return Promise.reject(error);
    }
  };
};

export const setUserProfileAction = (payload) => (dispatch) => {
  dispatch(slice.setUserProfile(payload));
};

export const setLastHardRefreshTimeAction = () => async (dispatch) => {
  await dispatch(slice.setLastHardRefreshTime());
 };
export const setLastHardRefreshTimeOld = () => async (dispatch) => {
  await dispatch(slice.setLastHardRefreshOld());
 };

export const setAuthStateEmptyAction = () => (dispatch) => {
  dispatch(slice.setAuthStateEmpty());
};

export function filterDigitalAssets(collections) {
  return collections
    .map((collection) => {
      const digitalAssets = collection.digital_assets.filter((asset) => {
        const fileExtension = asset.asset_url.split(".").pop();
        return ["mpd"].includes(fileExtension);
      });

      if (digitalAssets.length === 0) {
        return null;
      }

      const filteredCollection = { ...collection };
      filteredCollection.digital_assets = digitalAssets;
      filteredCollection.digital_asset_ids = digitalAssets.map(
        (asset) => asset.id
      );
      filteredCollection.digital_asset_seq = digitalAssets.map(
        (asset) => asset.id
      );

      return filteredCollection;
    })
    .filter((collection) => collection !== null);
}
