import { createSlice } from "@reduxjs/toolkit";

const initState = {
  loading: false,
  isDevtoolsOpen: false,
  downloadInProgress: {
    content: null,
    progress: 0,
  },
  downloadQueue: [],
  toggleMenu: false,
  onlineStatus: window.navigator.onLine,
  isShowMobileMenu: false,
  retryDetails: {
    isShowModal: false,
    digital_assets: null,
  },
};

export const utilitySlice = createSlice({
  name: "utils",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      const { payload } = action;
      state.loading = payload;
    },
    setDevtoolsOpen: (state, action) => {
      const { payload } = action;
      state.isDevtoolsOpen = payload;
    },
    setDownloadInProgress: (state, action) => {
      const { payload } = action;
      state.downloadInProgress = payload;
    },

    setDownloadQueue: (state, action) => {
      const { payload } = action;
      state.downloadQueue = payload;
    },
    setToggleMenu: (state, action) => {
      const { payload } = action;
      state.toggleMenu = payload;
    },
    setOnlineStatus: (state, action) => {
      const { payload } = action;
      state.onlineStatus = payload;
    },
    setIsShowMobileMenu: (state, action) => {
      const { payload } = action;
      state.isShowMobileMenu = payload;
    },
    SET_RETRY_MODAL: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        retryDetails: {...state.retryDetails, ...payload},
      };
    }
  },
});
