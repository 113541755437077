import React from "react";
import { useSelector } from "react-redux";
import VideoCards from "../VideoCards/VideoCards";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const VideoCollectionsCard = ({ collection, offlineView = false }) => {
  const { dbIndex } = useSelector((state) => state.video);
  const { user } = useSelector((state) => state.auth);

  const filterOfflineVideo = (item) => {
    const isAvailableOffline = dbIndex.some(
      (offV) =>
        offV.originalManifestUri === item.asset_url &&
        offV.appMetadata.id === item.id &&
        offV.appMetadata.syid === user.sadhak_profile.syid
    );

    if (offlineView === false) {
      return !isAvailableOffline;
    }

    return isAvailableOffline;
  };

  const videos = collection.digital_assets.filter(filterOfflineVideo);

  if (videos.length === 0) {
    return <></>;
  }

  return (
    <section className="categories-section-box">
      <div className="container">
        <div className="categories-section-top">
          <Tippy content={collection.collection_name} placement="top">
            <h2>{collection.collection_name}</h2>
          </Tippy>
        </div>
        {collection.announcement_text && collection.announcement_text.length ? (
          <div className="announcement-box">
            &nbsp;
            <h3>Announcements</h3>
            <div className="card">
              <div className="card-body">
                <div className="announcement-content-wrap">
                  <ul>
                    {collection.announcement_text.map((text, i) => (
                      <li key={`announcement-${i}`}>{text}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="video-cards-wrap">
          {videos.map((video) => (
            <VideoCards key={video.id} digital_assets={video} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default VideoCollectionsCard;
