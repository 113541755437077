import { toast } from "react-toastify";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SwalAlert from "../../SwalAlert/SwalAlert";
import { setDownloadInProgressAction } from "../../../redux/utils/actions";
import { cancelDownload } from "../../../utils/offlineVideoStorage";

const DownloadButton = ({ getDownloadPercentage, downloadVideo }) => {
  const { downloadInProgress, onlineStatus } = useSelector(
    (state) => state.utils
  );

  const dispatch = useDispatch();

  const [isShowAlert, setIsShowAlert] = useState(false);

  const openModal = () => {
    setIsShowAlert(true);
  };

  const cancelDownloadCurrentFile = async () => {
    await cancelDownload();
    dispatch(setDownloadInProgressAction({ content: null, progress: 0 }));
    return toast.error("Download cancelled");
  };

  const onClickHandler = async () => {
    if (getDownloadPercentage() !== "") {
      return openModal();
    }
    return downloadVideo();
  };

  return onlineStatus ? (
    <button
      className={
        "download-btn" +
        (getDownloadPercentage() !== "" ? " download-inprogress" : "") +
        (!!downloadInProgress.content ? " grayscale" : "")
      } // add 'download-inprogress' class when download inprogress
      type="button"
      onClick={onClickHandler}
    >
      {getDownloadPercentage() !== "" ? (
        getDownloadPercentage()
      ) : (
        <>
          <span>Download</span>
          <i className="icon-download-alt"></i>
        </>
      )}
      <SwalAlert
        show={isShowAlert}
        title="Cancel Download?"
        confirmButtonText="Yes, cancel it"
        setAlertVisibility={setIsShowAlert}
        onConfirm={cancelDownloadCurrentFile}
      ></SwalAlert>
    </button>
  ) : (
    <></>
  );
};

export default DownloadButton;
