import React from "react";
import { useSelector } from "react-redux";
import VideoCollectionsCard from "../../components/Cards/VideoCollectionsCard/VideoCollectionsCard";

const Home = () => {
  const { user } = useSelector((state) => state.auth);
  const { dbIndex } = useSelector((state) => state.video);

  const doThisUserHasOnlineVideo = () => {
    let videos = [];
    user.collections.forEach((collection) => {
      videos = [...videos, ...collection.digital_assets];
    });
    return videos.some(
      // check if some of the video are not downloaded
      (x) => !dbIndex.some((offVideo) => offVideo.appMetadata.id === x.id) //returns true if video is not in offline
    );
  };

  return (
    <>
      {user.collections.map((collection) => (
        <VideoCollectionsCard
          key={collection.id}
          collection={collection}
          offlineView={false}
        />
      ))}

      {!doThisUserHasOnlineVideo() && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <h1>No videos to download</h1>
        </div>
      )}
    </>
  );
};

export default Home;
