import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ShakaPlayer from "shaka-player-react";
import "shaka-player/dist/controls.css";
import logServices from "../../services/logger";

import { getAllOfflineVideoList } from "../../utils/offlineVideoStorage";

const Video = () => {
  const { user } = useSelector((state) => state.auth);
  const { storage } = useSelector((state) => state.video);
  const { onlineStatus } = useSelector((state) => state.utils);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const videoList = await getAllOfflineVideoList();
        const offlineVideo = videoList.find(
          (video) => video?.originalManifestUri === state?.asset_url
        );
        let logStatus = "";
        if (offlineVideo) {
          logStatus = "ASSET_PLAYED_FROM_DOWNLOAD";
          setVideoUrl(offlineVideo?.offlineUri);
        } else {
          logStatus = "ASSET_PLAYED";
          setVideoUrl(state?.asset_url);
        }
        await logServices.logger({
          chrome_log: {
            user_id: user?.id,
            asset_id: state?.id,
            status: logStatus,
            date_time: new Date().toLocaleString().replaceAll("/", "-"),
            data: { message: "Video playing started", user },
          },
        });
      } catch (error) {
        console.error(error);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const addWatermark = () => {
      const fragment = document.createDocumentFragment();
      const watermark = fragment.appendChild(document.createElement("i"));
      watermark.classList = "watermark";
      watermark.textContent = `${user.sadhak_profile.syid} ${
        user?.sy_club?.id ? "- Forum ID: " + user?.sy_club?.id : ""
      }`;
      let shakaPlayerDiv = null;

      setInterval(function () {
        if (shakaPlayerDiv === null) {
          if ([...document.querySelectorAll(".watermark")].length > 0) {
            return;
          }

          shakaPlayerDiv = document.querySelector(".shaka-video-container");
          shakaPlayerDiv?.appendChild(fragment);
        }
        const getRandomValue = (max = 80, min = 10) =>
          Math.floor(Math.random() * (max - min + 1)) + min;

        const x = getRandomValue();
        const y = getRandomValue();
        watermark.style.top = `${y}%`;
        watermark.style.left = `${x}%`;
      }, 5000);
    };

    addWatermark();
  }, [user]);

  return (
    <div>
      <section className="categories-section-box">
        <div className="container" style={{ position: "relative" }}>
          {onlineStatus || videoUrl !== "" ? (
            <div>
              <div className="d-flex justify-content-start align-items-center mb-2">
                <button
                  className={"download-btn download-inprogress"}
                  style={{ position: "relative", minWidth: "auto" }}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
              <ShakaPlayer autoPlay src={videoUrl} />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "55vh" }}
            >
              Offline Video Not found
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Video;
