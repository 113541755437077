import { toast } from "react-toastify";
import store from "../redux/store";
import shaka from "shaka-player";
import Cookies from "universal-cookie";

import { INDEXED_DB } from "../constants/storage";
import { setDownloadQueueAction, setRetryModalAction } from "../redux/utils/actions";
import { videoStorageSlice } from "./../redux/videoStorage/slice";
import { set_UPDATE_DB_INDEX_action } from "../redux/videoStorage/actions";
import logServices from "../services/logger";
const { actions: slice } = videoStorageSlice;
const _1GB = 1000000000;

const cookies = new Cookies();

const initialConfiguration = {
  language: "en",
  subtitles: "en",
  quality: 480,
};

const initState = {
  configuration: initialConfiguration,
  dbIndex: [],
  isInit: false,
  isSupported: true,
  videos: [],
  player: null,
  storage: null,
};

export const initShakaStorage = async (dispatch) => {
  const videosJsonPromise = [];
  store
    .getState()
    .auth.user.collections.forEach((collection) =>
      videosJsonPromise.push(...collection.digital_assets)
    );
  shaka.polyfill.installAll();

  const isShakaPlayerSupported = shaka.Player.isBrowserSupported();
  const isShakaStorageSupported = shaka.offline.Storage.support();

  if (isShakaPlayerSupported && isShakaStorageSupported) {
    const player = new shaka.Player();

    window.storage = new shaka.offline.Storage(player);
    // make shaka dispatch progress events so that we can have a progress bar when downloading
    const progressCallback = (content, progress) => {
      window.dispatchEvent(
        new CustomEvent("storage-progress", { detail: { content, progress } })
      );
    };

    window.storage.configure({
      offline: {
        progressCallback,
      },
      preferredAudioLanguage: initialConfiguration.language,
      preferredTextLanguage: initialConfiguration.subtitles,
    });

    let storageListPromise = await getAllOfflineVideoList();

    const onError = (error) => {
      console.error("Error code", error.code, "object", error);
    };
    player.addEventListener("error", ({ detail }) => onError(detail));

    const qualityValue = cookies.get("userPreferredQuality");

    // get previous configuration from cookies
    const configuration = {
      language:
        cookies.get("userPreferredAudioLanguage") ||
        initialConfiguration.language,
      subtitles:
        cookies.get("userPreferredTextLanguage") ||
        initialConfiguration.subtitles,
      quality: qualityValue
        ? parseInt(qualityValue)
        : initialConfiguration.quality,
    };

    const [videos, dbIndex] = await Promise.all([
      videosJsonPromise,
      storageListPromise,
    ]);

    return dispatch(
      slice.set_INIT_DONE({
        configuration,
        dbIndex,
        isInit: true,
        isSupported: isShakaPlayerSupported && isShakaStorageSupported,
        player,
        videos,
      })
    );
  }

  // not supported
  // return dispatch({
  //   type: "INIT_DONE",
  //   payload: {
  //     ...initState,
  //     isInit: true,
  //     isSupported: false,
  //   },
  // });
  return dispatch(
    slice.set_INIT_DONE({
      ...initState,
      isInit: true,
      isSupported: false,
    })
  );
};

const getUsedStoragePercentage = (usage, quota) => Math.ceil((100 * usage) / quota);

export const saveOffline = async (digital_assets, info) => {
  const maxRetries = 1;
  const retryDelay = 2000;
  const { dispatch } = store;

  const retryDownload = async (url, options, retries = 0) => {
    try {
      const result = await window.storage.store(url, options).promise;
      return result;
    } catch (error) {
      if (error.code === shaka.util.Error.Code.BAD_HTTP_STATUS && retries < maxRetries) {
        console.warn(`Retrying download (${retries + 1}/${maxRetries})...`);
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        return retryDownload(url, options, retries + 1);
      } else {
        throw error;
      }
    }
  };

  try {
    const { asset_url, id: videoId } = digital_assets;
    const { downloadInProgress, downloadQueue } = store.getState().utils;
    const { quota, usage } = await getStorageEstimate();
    // const nextSize = +usage + asset_file_size / 1000;

    if (await checkOfflineAvailabilityByVideoId(videoId, info.syid)) {
      alert("You already have the video in your library.");
      return;
    }

    if (getUsedStoragePercentage(usage, quota) >= 90) {
      toast.error("You have less than 10% of your current storage left. Please remove a few items.");
      return;
    }

    if (quota - usage < 3) {
      toast.error(`Not enough storage! ${quota - usage}GB is remaining.`);
      return;
    }

    if (downloadInProgress?.content) {
      dispatch(setDownloadQueueAction([...downloadQueue, { digital_assets, info }]));
      toast.error("Added to download queue");
      return;
    }

    const [dbVideos] = await Promise.all([window.storage.list()]);
    dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: dbVideos }));

    toast.warn("Downloading started...");
    await retryDownload(asset_url, info);
    toast.success("Download completed successfully.");
  } catch (error) {
    console.error("Error during saveOffline operation:", error);
    if (error.code === shaka.util.Error.Code.BAD_HTTP_STATUS) {
      toast.error("Failed to download. Please report to admin.");
    }
    else {
      toast.error("Failed to download.");

    }
    dispatch(setRetryModalAction({ isShowModal: true, digital_assets }))
  }
};

export const cancelDownload = async () => {
  const { dispatch } = store;
  const { downloadQueue } = store.getState().utils;
  const { user } = store.getState().auth;
  const videoList = await getAllOfflineVideoList();

  await logServices.logger({
    chrome_log: {
      user_id: user?.id,
      asset_id: videoList[videoList.length -1]?.appMetadata?.id,
      status: "DOWNLOAD_CANCELLED",
      date_time: new Date().toLocaleString().replaceAll("/", "-"),
      data: { message: "Downloading cancelled", user },
    },
  });

  await window.storage.destroy();
  await initShakaStorage(dispatch);

  if (downloadQueue.length > 0) {
    const [firstVideoOnQueue, ...restVideos] = downloadQueue;

    dispatch(setDownloadQueueAction([...restVideos]));

    const [dbVideos] = await Promise.all([window.storage.list()]);
    dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: dbVideos }));

    if (downloadQueue.length > 0) {
      dispatch(setDownloadQueueAction([...restVideos]));
      // saveOffline(firstVideoOnQueue?.digital_assets, {
      //   downloaded: Date(),
      //   ...firstVideoOnQueue?.digital_assets,
      //   syid: user.sadhak_profile.syid,
      // });
      try {
        await window.storage.store(firstVideoOnQueue?.digital_assets?.asset_url, {
          downloaded: Date(),
          ...firstVideoOnQueue?.digital_assets,
          syid: user.sadhak_profile.syid,
        }).promise ;
      } catch (error) {
        dispatch(setRetryModalAction({ isShowModal: true, digital_assets: firstVideoOnQueue?.digital_assets }))
      }
    }

    // window.storage.store(asset_url, info);
  }
  // downloadFromQueue();
};

export const getAllOfflineVideoList = async () => {
  const [dbVideos] = await Promise.all([window.storage.list()]);
  return await dbVideos;
};

export const removeOffline = async (offlineUri) => {
  await window.storage.remove(offlineUri);
};

export const removeUnfinishedVideo = async () => {
  const { dispatch } = store;
  const offDB = await window.storage.list();
  const inCompleteDownload = offDB.find((v) => v.isIncomplete);
  if (inCompleteDownload) {
    console.log("Removing incomplete download");
    await window.storage.remove(inCompleteDownload.offlineUri);
    const dbIndex = await window.storage.list();
    dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: dbIndex }));
  }
};

export const removeExpiredVideo = async () => {
  const { dispatch } = store;
  const initialOfflineDBindex = await getAllOfflineVideoList();
  const { user } = store.getState().auth;

  initialOfflineDBindex.forEach(async (offlineVideo) => {
    let offlineVideoExpirationDate = new Date(
      `${offlineVideo.appMetadata.expires_at}`
    );

    // add one day from expiration time
    offlineVideoExpirationDate.setDate(
      offlineVideoExpirationDate.getDate() + 1
    );
    const dayDifferenceToCurrentTime =
      new Date(+offlineVideoExpirationDate) - new Date();

    if (dayDifferenceToCurrentTime < 0) {
      try {
        await removeOffline(offlineVideo.offlineUri);
        await logServices.logger({
          chrome_log: {
            user_id: user?.id,
            asset_id: offlineVideo.appMetadata.id,
            status: "EXPIRED_ASSET_DELETED",
            date_time: new Date().toLocaleString().replaceAll("/", "-"),
            data: { message: "Expired assets removed", user },
          },
        });
        // here need to add logger
        const offlineVideoDBindex = await getAllOfflineVideoList();
        dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: offlineVideoDBindex }));
      } catch (e) {
        console.log(e);
      }
    }
  });
};

export const deleteOfflineDatabase = async () => {
  const { user } = store.getState().auth;
  await window.indexedDB.deleteDatabase(INDEXED_DB);
  await logServices.logger({
    chrome_log: {
      user_id: user?.id,
      status: "DELETED_FROM_FILE_SYSTEM",
      date_time: new Date().toLocaleString().replaceAll("/", "-"),
      data: { message: "Deleted user database", user },
    },
  });
  window.location.reload(true);
};

export const downloadFromQueue =async () => {
  const { downloadQueue } = store.getState().utils;
  const { user } = store.getState().auth;
  const { dispatch } = store;

  if (downloadQueue.length > 0) {
    const [firstVideoOnQueue, ...restVideos] = downloadQueue;

    dispatch(setDownloadQueueAction([...restVideos]));
    saveOffline(firstVideoOnQueue?.digital_assets, {
      downloaded: Date(),
      ...firstVideoOnQueue?.digital_assets,
      syid: user.sadhak_profile.syid,
    });
    
    await logServices.logger({
      chrome_log: {
        user_id: user?.id,
        asset_id: firstVideoOnQueue?.digital_assets.id,
        status: "DOWNLOAD_INITIATED",
        date_time: new Date().toLocaleString().replaceAll("/", "-"),
        data: { message: "Downloading Initiated", user },
      },
    });
  }
};
export const getStorageEstimate = async () => {
  return await navigator.storage.estimate().then(({ quota, usage }) => ({
    quota: (quota / _1GB).toFixed(2),
    usage: (usage / _1GB).toFixed(2),
  }));
};

export const checkOfflineAvailabilityByVideoId = async (videoId, userSyid) => {
  const dbIndex = await getAllOfflineVideoList();
  return Boolean(
    dbIndex.find(
      (v) => v.appMetadata.id === videoId && v.appMetadata.syid === userSyid
    )
  );
};
