import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RoutingList from "../../../constants/RoutingList";
import { setUserProfileAction } from "../../../redux/auth/action";
import logServices from "../../../services/logger";
import {
  loginAuthCheckInitialValue,
  loginAuthCheckValidationSchema,
} from "./loginUtils";

const LoginAuthCheck = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = async (values) => {
    dispatch(setUserProfileAction({ ...user, isAuth: true }));
    // toast.success("Successfully signed in");
    // navigate(RoutingList.index);
  };

  useEffect(() => {
    const logFreshLogin = async () => {
      try {
        await logServices.logger({
          chrome_log: {
            user_id: user?.id,
            // asset_id: "",
            status: "FRESH_LOGIN",
            date_time: new Date().toLocaleString().replaceAll("/", "-"),
            data: {
              message: "You have successfully logged in.",
              user: user,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    logFreshLogin();
  }, [user]);

  return (
    <div>
      <div className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="modal-title">OATH LETTER</h3>
              <ul className="modal-list">
                <li>
                  I will attach utmost importance, respect and reverence to the
                  Forum Episodes handed in good faith to me by Shiv Yog Ashram.
                </li>
                <li>
                  I take this responsibility to preserve and protect this
                  inheritance from my Guru in every possible way and from
                  getting into wrong hands and pledge to ensure that it is not
                  copied, reproduced and circulated.
                </li>
                <li>
                  I also pledge that the content is not broadcasted anywhere, in
                  any form whatsoever.
                </li>
                <li>
                  I commit to make sure that this content will be opened only by
                  me during the course of the programme and in no case, by
                  anyone else.
                </li>
                <li>
                  I solemnly declare that I will retain this divine property in
                  my safe hands.
                </li>
                <li>
                  My association with Shiv Yog can be cancelled if any of my
                  activities is not in accordance to the guidelines of the Shiv
                  Yog Ashram.
                </li>
              </ul>
              <Formik
                enableReinitialize={true}
                initialValues={loginAuthCheckInitialValue}
                validationSchema={loginAuthCheckValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  submitHandler(values);
                }}
              >
                <Form>
                  <div className="checkbox-section-wrap">
                    <div className="form-check custom-checkbox">
                      <div className="label-text">
                        <Field
                          type="checkbox"
                          className="form-check-input jsIAgreeTermsPolicy"
                          id="checkBoxWhatsapp"
                          name="isAuth"
                        />
                        <label
                          className="checkbox-wrap form-check-label"
                          htmlFor="checkBoxWhatsapp"
                        ></label>
                        I solemnly declare to have read and understood the terms
                        and condition of Shiv Yog Ashram and completely agree to
                        abide by the guidelines specified therein.
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="isAuth"
                    render={(msg) => <span className="error">{msg}</span>}
                  />
                  <div className="modal-btns">
                    <button className="btn btn-outline-red" type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAuthCheck;
