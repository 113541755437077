import Tippy from "@tippyjs/react";
import React, { useState, useEffect } from "react";

import SwalAlert from "../../components/SwalAlert/SwalAlert";

import {
  deleteOfflineDatabase,
  getStorageEstimate,
} from "../../utils/offlineVideoStorage";
import "tippy.js/dist/tippy.css"; // optional

const StorageInfo = () => {
  const [storageInfo, setStorageInfo] = useState({ quota: 0, usage: 0 });
  const [isShowAlert, setIsShowAlert] = useState(false);

  const openModal = () => {
    setIsShowAlert(true);
  };

  useEffect(() => {
    const getData = async () => {
      const { quota, usage } = await getStorageEstimate();
      setStorageInfo({ quota, usage });
    };
    getData();
  }, []);

  return (
    <>
      <section className="page-header-box">
        <div className="container">
          <div className="page-header">
            <h1>Having trouble playing the downloaded files?</h1>
            {/* <p>Offline saved files info will be here.</p> */}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="downloads-wrap">
            <Tippy content={"Clear all downloaded videos"} placement="top">
              <button
                className="delete-all"
                onClick={openModal}
                disabled={storageInfo.usage === 0}
              >
                <i className="icon-trash"></i>
                <span>Clear all downloaded videos</span>
              </button>
            </Tippy>
          </div>
          <div className="no-downloads-wrap">
            <p></p>
          </div>
        </div>
      </section>
      <SwalAlert
        show={isShowAlert}
        title="Delete offline database?"
        text="Every video will be deleted"
        confirmButtonText="Yes, delete all!"
        setAlertVisibility={setIsShowAlert}
        onConfirm={deleteOfflineDatabase}
      ></SwalAlert>
    </>
  );
};

export default StorageInfo;
