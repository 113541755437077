import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useDispatch, useSelector } from "react-redux";

import ToastAlert from "./components/Toast/Toast";
import Loading from "./components/Loading/Loading";
import Routing from "./components/Routing/Routing";
import ErrorPage from "./components/ErrorPage/ErrorPage";

import videoServices from "./services/videoServices";

import "./assets/scss/_theme.scss";
import { INDEXED_DB } from "./constants/storage";
import { setAuthStateEmptyAction } from "./redux/auth/action";
import {
  setDownloadInProgressAction,
  setDownloadQueueAction,
  setRetryModalAction,
} from "./redux/utils/actions";
import {
  set_INIT_action,
  set_UPDATE_DB_INDEX_action,
} from "./redux/videoStorage/actions";
import {
  getAllOfflineVideoList,
  removeExpiredVideo,
  removeUnfinishedVideo,
  saveOffline,
} from "./utils/offlineVideoStorage";
import logServices from "./services/logger";
import UpdateVersion from "./components/UpdateVersion/UpdateVersion";
import SwalAlert from "./components/SwalAlert/SwalAlert";

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isInit } = useSelector((state) => state.video);
  const { downloadInProgress, isDevtoolsOpen, downloadQueue, retryDetails } = useSelector(
    (state) => state.utils
  );

  const setAlertVisibility = (value) => {
    dispatch(setRetryModalAction({isShowModal: value}))
  }

  const retryDownload = async () => {
    dispatch(setDownloadInProgressAction({ content: null, progress: 0 }));
    dispatch(setRetryModalAction({ isShowModal: false}));
    
    await saveOffline(
      retryDetails.digital_assets,
      {
        downloaded: Date(),
        ...retryDetails.digital_assets,
        syid: user.sadhak_profile.syid,
      }
    );
  };

  const [isShowAlert, setShowAlert] = React.useState(false);

  useEffect(()=>{
    if(retryDetails.isShowModal){
      setShowAlert(true);
    }else{
      setShowAlert(false);
    }
  },[retryDetails.isShowModal])

  useBeforeunload((event) => {
    // prevent page closing while downloading
    if (downloadInProgress.content) {
      event.preventDefault();
      return "Downloading will be stopped, please wait..";
    }
  });

  useEffect(() => {
    !isInit && dispatch(set_INIT_action());
  });

  useEffect(() => {
    removeExpiredVideo();
    removeUnfinishedVideo();

    const clearOfflineDataAndNotifyDatabase = async () => {
      try {
        window.indexedDB.deleteDatabase(INDEXED_DB);
        await videoServices.reboot();
        dispatch(setAuthStateEmptyAction());
        toast.warn("Database cleared successfully, please login again");
        window.location.reload(true);
      } catch {}
    };

    if (isInit && user.reboot) {
      clearOfflineDataAndNotifyDatabase();
    }

    const handleProgressEvent = async (event) => {
      dispatch(setDownloadInProgressAction(event.detail));

      if (event.detail.progress >= .999999) {
        toast.success("Downloaded successfully.");
        const videoList = await getAllOfflineVideoList();
        await logServices.logger({
          chrome_log: {
            user_id: user?.id,
            asset_id: videoList[videoList.length -1]?.appMetadata?.id,
            status: "DOWNLOAD_SUCCESS",
            date_time: new Date().toLocaleString().replaceAll("/", "-"),
            data: { message: "Downloading success", user },
          },
        });
        dispatch(
          setDownloadInProgressAction({
            content: null,
            progress: 0,
          })
        );

        // const player = new shaka.Player();
        // const offlineStorage = new shaka.offline.Storage();
        // const [dbVideos] = await Promise.all([offlineStorage.list()]);
        const [dbVideos] = await Promise.all([window.storage.list()]);
        dispatch(set_UPDATE_DB_INDEX_action({ dbIndex: dbVideos }));

        if (downloadQueue.length > 0) {
          const [firstVideoOnQueue, ...restVideos] = downloadQueue;

          dispatch(setDownloadQueueAction([...restVideos]));
          saveOffline(firstVideoOnQueue?.digital_assets, {
            downloaded: Date(),
            ...firstVideoOnQueue?.digital_assets,
            syid: user.sadhak_profile.syid,
          });
          
          await logServices.logger({
            chrome_log: {
              user_id: user?.id,
              asset_id: firstVideoOnQueue?.digital_assets.id,
              status: "DOWNLOAD_INITIATED",
              date_time: new Date().toLocaleString().replaceAll("/", "-"),
              data: { message: "Downloading Initiated", user },
            },
          });
        }
      }
    };
    window.addEventListener("storage-progress", handleProgressEvent);
    return () => {
      // clean up when unmounting
      window.removeEventListener("storage-progress", handleProgressEvent);
    };
  }, [dispatch, downloadQueue, isInit, user]);

  if (isDevtoolsOpen) {
    return <ErrorPage message="Something went wrong!" />;
  }

  return (
    <>
      <UpdateVersion />
      <Loading />
      <Routing />
      <ToastAlert />
      <SwalAlert
        show={isShowAlert}
        title="Download failed"
        text = "Do you want to retry downloading the video?"
        confirmButtonText="Retry"
        setAlertVisibility={setAlertVisibility}
        onConfirm={retryDownload}
        // Deny button is use for the tracking the cancel button click
        showDenyButton={true}
        denyButtonText="Cancel"
        showCancelButton={false}
        preDeny={() => {
          window.location.reload(true);
        }}
      ></SwalAlert>
    </>
  );
};

export default App;
