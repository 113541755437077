import config from "../config";
import http from "../utils/http";

const reboot = async () => {
  const url = `${config.apiUrl}${config.endPoints.reboot.changeStatus}`;
  return http.get(url);
};

const videoServices = {
  reboot,
};

export default videoServices;
