import React, { useEffect, useRef } from "react";

const LoadingSpinner = () => {
  const spinnerElement = useRef(null);

  useEffect(() => {
    const showPage = () => {
      spinnerElement.current[0].style.display = "none";
    };
    const spinner = setTimeout(showPage, 3000);

    return () => {
      clearTimeout(spinner);
    };
  }, []);

  return (
    <div ref={spinnerElement} className="spinner-wrapper">
      <div className="spinner">
        <div className="sk-circle">
          <div className="sk-circle1 sk-child"></div>
          <div className="sk-circle2 sk-child"></div>
          <div className="sk-circle3 sk-child"></div>
          <div className="sk-circle4 sk-child"></div>
          <div className="sk-circle5 sk-child"></div>
          <div className="sk-circle6 sk-child"></div>
          <div className="sk-circle7 sk-child"></div>
          <div className="sk-circle8 sk-child"></div>
          <div className="sk-circle9 sk-child"></div>
          <div className="sk-circle10 sk-child"></div>
          <div className="sk-circle11 sk-child"></div>
          <div className="sk-circle12 sk-child"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
