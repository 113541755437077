import { videoStorageSlice } from "./slice";
import { initShakaStorage } from "../../utils/offlineVideoStorage";

const { actions: slice } = videoStorageSlice;

export const set_INIT_action = (payload) => (dispatch) => {
  initShakaStorage(dispatch);
};

export const set_INIT_DONE_action = (payload) => (dispatch) => {
  dispatch(slice.set_INIT_DONE(payload));
};

export const set_UPDATE_DB_INDEX_action = (payload) => (dispatch) => {
  dispatch(slice.set_UPDATE_DB_INDEX(payload));
};

export const setVideoStateEmptyAction = () => (dispatch) => {
  dispatch(slice.setEmpty());
};
