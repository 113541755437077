import { useSelector } from "react-redux";

import LoadingSpinner from "./LoadingSpinner";

const Loading = () => {
  const loading = useSelector((state) => state.utils.loading);

  return <>{loading && <LoadingSpinner />}</>;
};

export default Loading;
