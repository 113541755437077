import React from "react";
import { useState } from "react";
import SwalAlert from "../../SwalAlert/SwalAlert";

const RemoveButton = ({ removeMedia }) => {
  const [isShowAlert, setIsShowAlert] = useState(false)
  
  const openModal = () => {
    setIsShowAlert(true)
  }
  return (
    <button className="download-btn" type="button" onClick={openModal}>
      <span>Remove</span>
      <i className="icon-downloads"></i>
      <SwalAlert show={isShowAlert} setAlertVisibility={setIsShowAlert} onConfirm={removeMedia} ></SwalAlert>

    </button>
  );
};

export default RemoveButton;
